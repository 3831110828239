.gallery-container {
    background-color: #0C1210;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.slide-image {
    margin-left: auto;
    margin-right: auto;
    display: block;
    max-height: 100%;
}

.gallery-text {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 20px 20px 50px 20px;
    background-color: #000000aa;
    text-align: center;
}

.gallery-text span {
    color: white;
}

.unchecked-photo-container {
    margin-right: 50px;
}

.unchecked-photo-container img {
    height: 500px;
}

.unchecked-photo-container img {
    height: 500px;
}
